// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~admin-lte/dist/css/adminlte.css';

@import '~sweetalert2/dist/sweetalert2.css';
@import '~daterangepicker/daterangepicker.css';

@import '~apexcharts/dist/apexcharts.css';
